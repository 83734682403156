import BenefitCard from 'components/careers/BenefitCard'
import OpenPositions from 'components/careers/OpenPositions'
import Stats from 'components/digital-solutions/Stats'
import Modal from 'components/global/Modal'
import SectionTitle from 'components/global/SectionTitle'
import VideoEmbed from 'components/global/VideoEmbed'
import { Section, Row, Col } from 'components/grid'
import Layout from 'components/Layout'
import OverlayVideo from 'components/overlay-video'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { employeeQuotes } from 'components/about-us/employeeQuotes'
import EmployeeQuoteSlide from 'components/about-us/EmployeeQuoteSlide'
import shuffle from 'utils/shuffle'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { H1 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'
import { Button } from '@arckit/ui'

export default function AboutUsPage() {
	const data = useStaticQuery<Query>(query).allContentfulAsset.edges[0].node.file.url
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [modalTitle, setModalTitle] = useState('')
	const [employees, setEmployees] = useState(employeeQuotes)

	useEffect(() => {
		setEmployees(shuffle(employeeQuotes))
	}, [])

	const handleModalClose = () => setIsModalOpen(false)

	const handleModalOpen = (title: string) => {
		setIsModalOpen(true)
		setModalTitle(title)
	}
	return (
		<Layout pageName="About Us">
			<Section>
				<div className="absolute top-0 left-0 h-full w-full bg-cover bg-center bg-no-repeat xl:bg-contain" style={{ backgroundImage: `url('${data}')` }} />
				<Row className="relative py-20 lg:py-40">
					<Col width="w-full md:w-3/4" className="mx-auto text-center">
						<H1 className="text-white" style={{ textShadow: '3px 3px 5px rgba(0, 0, 0, 0.4)' }}>
							Founded by Educators. Run by Educators. 100% Employee Owned.
						</H1>
					</Col>
				</Row>
			</Section>
			<Section margin="mt-10 sm:mt-20">
				<Row middle>
					<Col width="md:w-1/3 xl:w-1/2" className="hidden bg-gray-200 md:block">
						<div className="-mr-28 py-20">
							<StaticImage src="../../static/images/about-us/esop.png" alt="employee owned collage" />
						</div>
					</Col>
					<Col width="w-full md:w-2/3 xl:w-1/2">
						<div className="pl-0 md:pl-28">
							<P size="md" className="mb-3" shade="dark">
								Over 25 years ago, a reading specialist and university professor founded American Reading Company to address a crisis: too many students were not
								yet reading at grade level and they, their teachers, and their parents were unsure of how to fix it. The 2022 NAEP results indicate that not only
								does the reading crisis in the U.S. persist, students performing at the lowest levels continue to fall further behind their peers.
							</P>
							<P size="md" className="mb-3" shade="dark">
								We believe that all students deserve the opportunity to achieve reading success using proven resources and methods like foundational skills
								instruction, daily reading and writing practice, and books that build knowledge while reflecting each child's world. ARC partners with schools and
								districts to establish, scale, and sustain high performing continuous improvement systems where students, educators, and their communities flourish.
								This is our work, our mission.
							</P>
							<span className="font-semibold">Every child in America reading and writing on, or above, grade level.</span>
						</div>
					</Col>
				</Row>
			</Section>
			<Section margin="mt-10 sm:mt-20">
				<Row>
					<BenefitCard
						icon="/images/careers/livingWage.svg"
						title="Run by Educators"
						text="In 2022, ARC transitioned to an Employee Stock Ownership Program (ESOP). ARC is also a benefit corporation — meeting the highest standards of social and environmental performance, transparency, and accountability to balance profit and purpose."
					/>
					{/* <BenefitCard
						icon="/images/careers/profitSharing.svg"
						title="Profit Sharing"
						text="ARC distributes 10% of company profits amongst full-time employees who have worked here for at least one full year."
					/> */}
					<BenefitCard
						icon="/images/careers/openBookPolicy.svg"
						title="Open Book Policy"
						text="We want our employees to be an integral part in the company. Our financial results are shared daily, company-wide."
					/>
					<BenefitCard
						icon="/images/careers/familyFriendly.svg"
						title="Family Friendly"
						text="ARC provides staff with flexibility to support their families including: flexible hours, paid family leave, kid-friendly building/zoom meetings, and the ability for new parents to bring their babies to work with them."
					/>
					<BenefitCard
						icon="/images/careers/casualEnvironment.svg"
						title="Casual Environment"
						text="Our employees wear their VITAL ENERGY to work every day — they have the power to choose how they show up and contribute to our mission. Our only rule is closed-toed shoes in the warehouse... you know, for safety. "
					/>
					<BenefitCard
						icon="/images/careers/paidTimeOff.svg"
						title="Paid Time Off"
						text="All full-time employees receive two full weeks of vacation when ARC closes for the week of July 4th and the last week of December, 9 additional paid holidays, and 15 flexible days of paid time off to use at their discretion."
					/>
					<BenefitCard
						icon="/images/careers/homeLibraries.svg"
						title="Home Libraries"
						text="Full-time employees receive a 50-book home library customized for their children's interests every calendar year."
					/>
					<BenefitCard
						icon="/images/careers/alwaysLearning.svg"
						title="Always Learning"
						text="Professional development through book studies, featured speakers, and on-site training drives learning."
					/>
					<BenefitCard
						icon="/images/careers/dogFriendly.svg"
						title="Dog Friendly"
						text="Staff are welcome to bring their dogs to work, if they pass their interview process."
					/>
				</Row>
			</Section>
			<Section margin="mt-10">
				<Row center>
					<Col width="w-full md:w-1/2" className="mb-6 md:mb-0">
						<OverlayVideo noOverlay title="Family Libraries for ARC Employees" />
						<P shade="darker" size="sm" className="mx-auto mt-3 w-full text-center sm:w-5/6">
							Classroom Library Designer Jessica Smith-Harper talks about the process of creating ARC's most unique benefit: Family Libraries.
						</P>
					</Col>
					<Col width="w-full md:w-1/2" className="mb-6 md:mb-0">
						<OverlayVideo noOverlay title="Spanish Libraries Support Learning at Any Age" />
						<P shade="darker" size="sm" className="mx-auto mt-3 w-full text-center sm:w-5/6">
							An ARC Spanish content developer shares how literacy cultivates language learners inside and outside the classroom.
						</P>
					</Col>
				</Row>
			</Section>
			<Section margin="mt-10 sm:mt-20">
				<SectionTitle
					title="Our Strength is in Our Diversity"
					text="ARC is committed to cultivating, fostering, and preserving a culture of diversity and inclusion. We embrace and encourage our
employees' differences, life experiences, inventiveness, self-expression, and talent. Their uniqueness represents a significant
part of our culture and corporate achievement."
				/>
				<Row>
					<Col width="w-full md:w-3/4" className="mx-auto mb-6">
						<div className="relative w-full">
							<button
								className="absolute top-1/2 left-1/2 z-50 text-white opacity-75 hover:opacity-100 focus:outline-none"
								style={{ transform: 'translate(-50%, -50%)' }}
								onClick={() => handleModalOpen('Dress Code')}
							>
								<svg xmlns="http://www.w3.org/2000/svg" className="h-24 w-24" viewBox="0 0 20 20" fill="currentColor">
									<path
										fillRule="evenodd"
										d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
										clipRule="evenodd"
									/>
								</svg>
							</button>
							<VideoEmbed src="https://player.vimeo.com/video/585911463" title="Dress Code" />
						</div>
						<div>
							<P shade="darker" size="sm" className="mx-auto mt-3 w-full text-center sm:w-5/6">
								ARC employees wear their vital energy to work. What is vital energy anyway?
							</P>
						</div>
					</Col>
				</Row>
			</Section>
			<Section margin="mt-10 sm:mt-20">
				<SectionTitle
					wide
					title="A Deliberately Developmental Organization"
					text='ARC grounds its work in an "idea meritocracy" in which we all operate from the same core Work Principles. This culture encourages
all employees to meaningfully contribute to the innovation and success of our deliberately developmental organization. Staff
members are challenged to set ambitious personal and professional goals, and grow into their dream role. It&#39;s the organization&#39;s
responsibility to identify the goals that benefit the whole and provide the training, resources, and staffing necessary to reach those
objectives. From the moment someone joins ARC, they are enrolled in leadership development groups that progressively train them
to lead as an individual, a manager, and a director of managers.'
				/>
			</Section>
			<Section className="bg-ab-100">
				<Row>
					<Carousel showThumbs={false} showStatus={false}>
						{employees.map((employee) => (
							<EmployeeQuoteSlide key={employee.id} employee={employee} />
						))}
					</Carousel>
				</Row>
			</Section>
			<Section margin="mt-20 md:mt-40">
				<SectionTitle title="Become One of Our Trusted Partners" />
				<Stats />
			</Section>
			<div className="mt-0 sm:mt-10">
				<StaticImage src="../../static/images/about-us/25th-anniversary-collage.png" alt="collage with employees" />
			</div>
			<Section margin="mt-20 sm:mt-40" className="bg-ab-100">
				<Row center className="relative z-auto">
					<div className="mt-14 max-w-3xl px-4">
						<div className="relative">
							<svg xmlns="http://www.w3.org/2000/svg" className="absolute -top-10 left-0 z-10 h-20 md:-left-20" viewBox="0 0 130.51 115">
								<path
									d="m43.65 0 16.26 19.53Q41 38.43 36 45.76a62.39 62.39 0 0 0-7.91 17.88 19.6 19.6 0 0 1 5.49-3.23 16.16 16.16 0 0 1 5.63-1q8.27 0 14.47 7.56a27.88 27.88 0 0 1 6.2 18.27q0 12.6-7.91 21.19a25.31 25.31 0 0 1-19.3 8.57 28.34 28.34 0 0 1-16.19-5.28A35.26 35.26 0 0 1 4.36 95.23 49.51 49.51 0 0 1 0 73.88q0-20 12.05-39.07A143.1 143.1 0 0 1 43.65 0m70.49 0 16.22 19.53q-18.78 18.9-23.76 26.23a61.23 61.23 0 0 0-8 17.88 19.41 19.41 0 0 1 5.48-3.23 16.1 16.1 0 0 1 5.62-1q8.25 0 14.51 7.56a27.72 27.72 0 0 1 6.26 18.27q0 12.6-8 21.19a25.44 25.44 0 0 1-19.29 8.57A28.29 28.29 0 0 1 87 109.72a35.19 35.19 0 0 1-12.06-14.49 49.52 49.52 0 0 1-4.33-21.35q0-20 12-39.07A142.78 142.78 0 0 1 114.14 0"
									fill="#0063A2"
								/>
							</svg>
							<p className="relative z-20 text-center text-lg italic text-white md:text-2xl md:text-xl">
								My students have loved being able to call themselves zoologists or entomologists. Learning about animals and bugs is super intriguing and engages
								students in writing and reading. The cross-curricular aspect of ARC Core allows students to soar and reach their academic potential.
							</p>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="absolute -bottom-10 right-0 z-10 h-20 rotate-180 transform md:-right-28 lg:bottom-0"
								viewBox="0 0 130.51 115"
							>
								<path
									d="m43.65 0 16.26 19.53Q41 38.43 36 45.76a62.39 62.39 0 0 0-7.91 17.88 19.6 19.6 0 0 1 5.49-3.23 16.16 16.16 0 0 1 5.63-1q8.27 0 14.47 7.56a27.88 27.88 0 0 1 6.2 18.27q0 12.6-7.91 21.19a25.31 25.31 0 0 1-19.3 8.57 28.34 28.34 0 0 1-16.19-5.28A35.26 35.26 0 0 1 4.36 95.23 49.51 49.51 0 0 1 0 73.88q0-20 12.05-39.07A143.1 143.1 0 0 1 43.65 0m70.49 0 16.22 19.53q-18.78 18.9-23.76 26.23a61.23 61.23 0 0 0-8 17.88 19.41 19.41 0 0 1 5.48-3.23 16.1 16.1 0 0 1 5.62-1q8.25 0 14.51 7.56a27.72 27.72 0 0 1 6.26 18.27q0 12.6-8 21.19a25.44 25.44 0 0 1-19.29 8.57A28.29 28.29 0 0 1 87 109.72a35.19 35.19 0 0 1-12.06-14.49 49.52 49.52 0 0 1-4.33-21.35q0-20 12-39.07A142.78 142.78 0 0 1 114.14 0"
									fill="#0063A2"
								/>
							</svg>
						</div>
						<div className="mx-auto my-6 w-32 border-b-4 border-b-ab-100"></div>
						<p className="text-center text-white">– Teacher, Division Street Elementary School</p>
					</div>
				</Row>
			</Section>
			<Section id="positions" margin="md:mt-40 mt-20">
				<SectionTitle
					title="Bring Your Genius to Our Collective Genius"
					text="As we work to transform schools into ones that we want to send our own children to, we're always looking for talented, friendly, like-minded people."
				/>
				<div className="mb-10 flex justify-center">
					<a
						href="https://www.paycomonline.net/v4/ats/web.php/jobs?clientkey=E93CCC6D454104319EA9B7C781FF657C&fromClientSide=true"
						target="_blank"
						rel="noreferrer noopener"
					>
						<Button>View Open Positions</Button>
					</a>
				</div>
			</Section>
			<Modal title={modalTitle} isOpen={isModalOpen} handleClose={handleModalClose} wide>
				<OverlayVideo title={modalTitle} autoPlay />
			</Modal>
		</Layout>
	)
}

interface Query {
	allContentfulAsset: {
		edges: {
			node: { file: { url: string } }
		}[]
	}
}

const query = graphql`
	query {
		allContentfulAsset(filter: { title: { eq: "Rainbow Collage" } }) {
			edges {
				node {
					file {
						url
					}
				}
			}
		}
	}
`
