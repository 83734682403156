import { Col } from 'components/grid'
import { P } from 'components/typography/Paragraph'
import Title from 'components/typography/Title'
import React from 'react'

interface Props {
	icon: string
	title: string
	text: string
}

export default function BenefitCard({ icon, title, text }: Props) {
	return (
		<Col width="w-full sm:w-1/2 lg:w-1/3" className="mb-6">
			<div className="h-full rounded border bg-white shadow-md">
				<div className="p-6 text-center ">
					<img src={icon} alt={title} className="mx-auto h-32 w-32" />
				</div>
				<div className="p-6 text-center">
					<Title className="mb-2">{title}</Title>
					<P size="sm">{text}</P>
				</div>
			</div>
		</Col>
	)
}
