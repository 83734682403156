import React from 'react'

interface Props {
	employee: {
		id: string
		name: string
		title: string
		book: string
		quote: JSX.Element[]
	}
}

export default function EmployeeQuoteSlide({ employee }: Props) {
	return (
		<div>
			<div className="flex items-center gap-20 py-10 px-4">
				<div className="hidden w-1/4 sm:block">
					<img src={`/images/about-us/${employee.id}.jpg`} alt={employee.name} className="rounded-full" />
				</div>
				<div className="w-full sm:w-1/2">
					<div className="relative">
						<svg xmlns="http://www.w3.org/2000/svg" className="absolute -top-5 -left-16 z-10 hidden h-12 sm:block" viewBox="0 0 130.51 115">
							<path
								d="m43.65 0 16.26 19.53Q41 38.43 36 45.76a62.39 62.39 0 0 0-7.91 17.88 19.6 19.6 0 0 1 5.49-3.23 16.16 16.16 0 0 1 5.63-1q8.27 0 14.47 7.56a27.88 27.88 0 0 1 6.2 18.27q0 12.6-7.91 21.19a25.31 25.31 0 0 1-19.3 8.57 28.34 28.34 0 0 1-16.19-5.28A35.26 35.26 0 0 1 4.36 95.23 49.51 49.51 0 0 1 0 73.88q0-20 12.05-39.07A143.1 143.1 0 0 1 43.65 0m70.49 0 16.22 19.53q-18.78 18.9-23.76 26.23a61.23 61.23 0 0 0-8 17.88 19.41 19.41 0 0 1 5.48-3.23 16.1 16.1 0 0 1 5.62-1q8.25 0 14.51 7.56a27.72 27.72 0 0 1 6.26 18.27q0 12.6-8 21.19a25.44 25.44 0 0 1-19.29 8.57A28.29 28.29 0 0 1 87 109.72a35.19 35.19 0 0 1-12.06-14.49 49.52 49.52 0 0 1-4.33-21.35q0-20 12-39.07A142.78 142.78 0 0 1 114.14 0"
								fill="#0063A2"
							/>
						</svg>
						<p className="relative z-20 text-left text-lg italic text-white lg:text-2xl">{employee.quote.map((q) => q)}</p>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="absolute bottom-0 -right-10 z-10 hidden h-12 rotate-180 transform sm:block lg:-bottom-5"
							viewBox="0 0 130.51 115"
						>
							<path
								d="m43.65 0 16.26 19.53Q41 38.43 36 45.76a62.39 62.39 0 0 0-7.91 17.88 19.6 19.6 0 0 1 5.49-3.23 16.16 16.16 0 0 1 5.63-1q8.27 0 14.47 7.56a27.88 27.88 0 0 1 6.2 18.27q0 12.6-7.91 21.19a25.31 25.31 0 0 1-19.3 8.57 28.34 28.34 0 0 1-16.19-5.28A35.26 35.26 0 0 1 4.36 95.23 49.51 49.51 0 0 1 0 73.88q0-20 12.05-39.07A143.1 143.1 0 0 1 43.65 0m70.49 0 16.22 19.53q-18.78 18.9-23.76 26.23a61.23 61.23 0 0 0-8 17.88 19.41 19.41 0 0 1 5.48-3.23 16.1 16.1 0 0 1 5.62-1q8.25 0 14.51 7.56a27.72 27.72 0 0 1 6.26 18.27q0 12.6-8 21.19a25.44 25.44 0 0 1-19.29 8.57A28.29 28.29 0 0 1 87 109.72a35.19 35.19 0 0 1-12.06-14.49 49.52 49.52 0 0 1-4.33-21.35q0-20 12-39.07A142.78 142.78 0 0 1 114.14 0"
								fill="#0063A2"
							/>
						</svg>
					</div>
					<div className="mt-8 flex flex-wrap items-center gap-3">
						<div className="block h-20 w-20 sm:hidden">
							<img src={`/images/about-us/${employee.id}.jpg`} alt={employee.name} className="rounded-full" />
						</div>
						<p className="mb-0 text-white">
							– {employee.name}, {employee.title}
						</p>
					</div>
				</div>
				<div className="hidden w-1/4 md:block">
					<img src={`/images/about-us/${employee.book}.jpg`} alt={employee.book} />
				</div>
			</div>
		</div>
	)
}
