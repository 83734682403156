import React from 'react'

export const employeeQuotes = [
	{
		id: 'tim-ferry',
		name: 'Tim Ferry',
		title: 'Executive Director of Supply Chain',
		book: 'crucial-accountability',
		quote: [
			<>
				<string>Crucial Accountability</string> made a giant impact for me as a newly minted manager. ARC U discussion of the text provided me with a framework for
				necessary but tough conversations. I learned to incorporate the elements of effective communication into 'moves' that if present, helped to make hard conversations
				more approachable with greater mutual understanding.
			</>
		]
	}
]
